<template>
    <yh-page fullscreen>
        <div class="goods-page">

            <div class="left-filter" :class="{'expand':expandFilter}">

                <button class="filter-expand-btn" @click="expandFilter = !expandFilter">
                    <span class="iconfont pc-zhankai closed"></span>
                    <span class="iconfont pc-shouqi opened"></span>
                </button>

                <perfect-scrollbar class="left-filter-scroll" :options="{suppressScrollX:false}">

                    <template v-for="(item,key) in pickers">
                        <div class="left-filter-item" :key="key" :class="{'expand': item.expand }">
                            <div class="filter-name">{{ item.name }}
                                <span class="icon" @click=" item.expand = !item.expand ">
                                    <span class="closed iconfont pc-xiajiantou"></span>
                                    <span class="opened iconfont pc-shangjiantou"></span>
                                </span>
                            </div>
                            <div class="filter-content">
                                <template v-for="(sub,sk) in item.items">
                                    <div class="filter-row" :key="sk">

                                        <check-box :name="item.type"
                                                   :value="sub"
                                                   :checked=" item.selected === sub "
                                                   @change="(name, value, selected) => checkBoxSelected(name,value,selected,item)">
                                            {{ sub.name }}
                                        </check-box>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </template>

                </perfect-scrollbar>

            </div>

            <div class="left-filter-empty" :class="{'expand':expandFilter}"></div>

            <perfect-scrollbar class="goods-content" :options="{suppressScrollX:false}" @ps-y-reach-end="loadNextPage">
                <div class="search">
                    <input class="search-input" v-model="keywords" type="text" placeholder="搜索您感兴趣的搭配"/>
                    <button @click="search" class="btn">搜索</button>
                </div>

                <div class="list-filter">

                    <div class="selected-list">

                        <template v-for="(picker,pk) in pickers">
                            <div v-if="picker.selected" :key="pk">{{ picker.selected.name }}
                                <span @click="clearSelected(picker)" class="iconfont pc-guanbi"></span>
                            </div>
                        </template>
                    </div>


                    <div class="sorts">
                        <template v-for="(label,slug) in sorts">
                            <button :key="slug"
                                    :class="{'selected' : params.order_by === slug }"
                                    @click="sortClicked(slug)">{{ label }}
                                <template v-if=" slug === 'price' ">

                                    <template v-if="params.order_by === 'price' ">
                                        <span v-if="params.order_type === 'asc' " class="iconfont pc-shang"></span>
                                        <span v-else class="iconfont pc-xia"></span>
                                    </template>
                                    <span v-else class="iconfont pc-weixuan"></span>
                                </template>
                            </button>
                        </template>
                    </div>

                </div>


                <div class="goods-list">

                    <template v-for="(item,k) in goods">

                        <div class="goods-item" :key="k">
                            <l-a :href="`/collections/${item.id}`">
                                <outfit-item mode="yh" :outfit="item"></outfit-item>
                            </l-a>
                            <template v-if="role === 'ADMIN' && (typeof item.detect_recommends !== 'undefined' )">

                                <div class="rc-group">
                                    <template v-for="(age,k) in ages">

                                        <button @click="recItem(item,age.id)" :key="k" class="btn"
                                                :class="{ 'btn-white': item.ages.indexOf(age.id) < 0 }"
                                                type="button">{{ age.name }}
                                        </button>

                                    </template>
                                </div>

                            </template>
                        </div>

                    </template>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>

                    <div v-if="!loading && !goods.length" class="empty">
                        暂无单品数据哦
                    </div>
                    <div v-else-if="!loading && !hasMoreGoods" class="empty">
                        到底咯....
                    </div>
                    <div v-else-if="loading" class="empty">
                        加载中...
                    </div>

                </div>

            </perfect-scrollbar>
        </div>
    </yh-page>
</template>

<script>
    import CheckBox from "@/components/CheckBox";
    import api from "@/repo/api";
    import YhPage from "../../../layout/YhPage";
    import request from "../../../repo/request";
    import auth from "../../../auth";
    import OutfitItem from "../../../components/OutfitItem";
    import LA from "../../../components/LA";

    export default {
        name: "GoodsFilter",
        components: {LA, OutfitItem, YhPage, CheckBox},
        data() {
            return {
                goods: [],
                expandFilter: true,
                params: {
                    keywords: '',
                    gender: auth.gender(),
                    cate: 0,
                    order_by: '',
                    order_type: '',
                    price: '',
                    page: 1,
                },
                keywords: '',
                sorts: {
                    'normal': '综合',
                    'hot': '热度',
                    'new': '新品',
                    'price': '价格',
                },
                loading: true,
                hasMoreGoods: true,
                ages: [
                    {
                        'id': 122,
                        'name': '25岁及以下',
                    }, {
                        'id': 123,
                        'name': '26-38岁',
                    }, {
                        'id': 124,
                        'name': '39岁及以上',
                    }
                ],
                pickers: [
                    {
                        id: 0,
                        name: '价格',
                        items: [
                            {
                                name: '0~99',
                                value: '0-99',
                            }, {
                                name: '100~199',
                                value: '100-199',
                            }, {
                                name: '200~399',
                                value: '200-399',
                            }, {
                                name: '400以上',
                                value: '400-0',
                            }
                        ],
                        value: 'value',
                        type: 'price',
                        expand: true,
                        selected: null,
                    },
                    {
                        id: 5,
                        name: '适宜场合',
                        items: [],
                        type: 'occasion',
                        value: 'id',
                        expand: true,
                        selected: null,
                    },
                    {
                        id: 7,
                        name: '适宜季节',
                        items: [],
                        type: 'season',
                        value: 'id',
                        max: 2,
                        expand: true,
                        selected: null,
                    },
                    {
                        id: 10086,
                        name: '适宜人群类型',
                        items: [],
                        type: 'style',
                        value: 'id',
                        max: 3,
                        expand: true,
                        selected: null,
                    },
                    {
                        id: 8,
                        name: '偏好搭配风格',
                        items: [],
                        type: 'atmosphere',
                        value: 'id',
                        max: 2,
                        expand: true,
                        selected: null,
                    }
                ],
                role: '',
            }
        },
        mounted() {
            this.checkRole()
            this.getOptions()
            this.getGoods()
        },
        methods: {
            checkRole() {
                setTimeout(() => {
                    auth.check((res) => {
                        this.role = res ? auth.userInfo().setting.role : '';
                    })
                }, 1500)
            },
            search() {
                this.params.keywords = this.keywords

                this.loadFirstPage();
            },
            loadFirstPage() {
                this.params.page = 1
                this.hasMoreGoods = true
                this.goods = []
                this.getGoods()
            },
            loadNextPage() {
                if (!this.hasMoreGoods || this.loading) return

                this.params.page++;
                this.getGoods()
            },
            getGoods() {
                this.loading = true

                api.getOutfits(this.params, (data) => {

                    let goods = data.data.data

                    this.loading = false

                    this.hasMoreGoods = goods.length === data.data.per_page

                    if (goods.length === 0) {
                        return
                    }

                    goods.forEach(item => {
                        if (typeof item.detect_recommends === 'undefined') return

                        let ages = []

                        item.detect_recommends.forEach(el => {
                            ages.push(el.age_id)
                        })

                        item['ages'] = ages
                    })

                    this.goods = this.goods.concat(goods)
                })
            },
            checkBoxSelected(name, value, selected, picker) {


                picker.selected = selected ? value : ''

                this.params[name] = selected ? value[picker.value] : ''


                this.loadFirstPage();
            },
            getOptions() {
                request.get('/collocation/options/all', {}, (data) => {

                    this.options = data.data

                    this.pickGenderOptions()

                })
            },
            recItem(item, age) {
                if (item.loading) return

                item.loading = true

                let dta = {scheme_id: item.id, style_id: this.params.style, age_id: age}

                request.post('/collocation/scheme/detect-recommend', dta, (data) => {

                    item.loading = false

                    if (data.code === 0) {

                        let idx = item.ages.indexOf(age);

                        if (idx >= 0) {
                            item.ages.splice(idx, 1)
                        } else {
                            item.ages.push(age)
                        }

                        this.$forceUpdate()

                        return
                    }

                    alert(data.msg)
                })


            },
            pickGenderOptions() {

                if (!this.params.gender) return

                this.pickers.forEach(picker => {

                    let items = picker.items || []

                    this.options.forEach(item => {

                        let gender = +item.gender

                        if (gender !== this.params.gender && gender !== 0) return

                        if (item.parent_id !== picker.id) return

                        items.push(item)
                    })

                    picker.items = items
                })
            },
            sortClicked(slug) {

                let orderType = 'desc';

                if (slug === 'price' && this.params.order_by === slug) {
                    orderType = this.params.order_type === 'desc' ? 'asc' : 'desc';
                }

                this.params.order_type = orderType;
                this.params.order_by = slug;

                this.loadFirstPage();
            },
            expandObj(obj) {
                obj.expand = !obj.expand
                this.$forceUpdate()
            },
            clearSelected(picker) {

                picker.selected = null

                this.params[picker.type] = ''

                this.loadFirstPage();
            }
        }
    }
</script>

<style lang="less" scoped>
    .search {
        max-width: 670px;
        display: flex;
        flex-direction: row;
        margin: 0 auto 40px;

        input, button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        input {
            flex: 1;
            padding: 8px 16px;
            outline: none;
            border: 1px solid rgba(51, 51, 51, 0.8);
        }

        button {
            width: 70px;
            padding: 8px;
            border: none;
        }
    }

    .list-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 32px;

    }

    .sorts {
        display: flex;
        height: 33px;

        button {

            background-color: transparent;
            border: none;
            margin-right: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            cursor: pointer;

            &.selected {
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .iconfont {
            font-size: 14px;
        }
    }

    .filter-content .iconfont {
        margin-right: 5px;
    }

    .search-input::placeholder {
        color: #CCC;
    }

    .goods-page {
        /*padding: 128px 64px 0;*/
        display: flex;
    }

    .left-filter-empty {
        transition: all .3s;
        width: 0;

        &.expand {
            width: 335px;
        }
    }

    .left-filter {
        transition: all .3s;

        width: 335px;
        height: 100vh;

        position: fixed;
        z-index: 2;
        top: 0;
        left: -335px;


        .closed {
            display: inline-block;
        }

        .opened {
            display: none;
        }

        &.expand {
            left: 0;

            .closed {
                display: none;
            }

            .opened {
                display: inline-block;
            }
        }
    }

    .left-filter-scroll {
        padding: 128px 32px 0 64px;
        border-right: 1px solid #EEEEEE;
        height: 100vh;
    }

    .goods-page {
        height: 100vh;
        overflow: hidden;
    }

    .goods-content {
        padding: 128px 64px 0;
    }

    .goods-content {
        flex: 1;
    }

    .left-filter-item {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid #EEEEEE;
        overflow: hidden;
        position: relative;

        &:last-child {
            border-bottom: none;
        }

        .filter-name {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
        }

        .icon {
            float: right;
            cursor: pointer;

            .iconfont {
                font-size: 14px;
            }
        }

        .filter-content {
            height: 0;
            overflow: hidden;
            transition: height .3s;
        }

        .closed {
            display: inline-block !important;
        }

        .opened {
            display: none !important;
        }

        &.expand {
            .filter-content {
                height: auto;
            }

            .closed {
                display: none !important;
            }

            .opened {
                display: inline-block !important;
            }

            .filter-name {
                margin-bottom: 15px;
            }
        }
    }

    .filter-sub-rows {
        margin-top: 16px;
        padding-left: 22px;
        overflow: hidden;

        .filter-row {
            margin-bottom: 10px;
        }
    }

    .filter-row {
        overflow: hidden;
        margin-bottom: 16px;
        flex-direction: row;

        /deep/ span {
            color: #999999;
        }
    }

    .filter-expand-btn {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        position: absolute;
        right: -20px;
        top: 128px;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;
        font-size: 16px;
    }

    .cate-expand-btn {
        float: right;
        cursor: pointer;

        span {
            font-size: 12px;
        }
    }

    .selected-list {
        display: flex;

        div {
            margin-right: 15px;
            background: #F5F5F5;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            padding-left: 12px;
            padding-right: 12px;
        }

        .iconfont {
            font-size: 12px;
            cursor: pointer;
        }
    }

    .goods-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        min-height: 500px;

        .empty {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            color: #333333;

            img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .goods-item {
        display: block;

        a {
            text-decoration: none;
        }

        width: 304px;
        margin-bottom: 32px;
        position: relative;

        /deep/ .outfit-item {

            height: 368px;

            .image-container {
                width: 304px;
                height: 304px;
            }
        }
    }

    .rc-group {
        position: absolute;
        right: 0;
        top: 0;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        button {
            margin-bottom: 5px;
            font-size: 12px;
            padding: 5px 10px;
        }
    }
</style>